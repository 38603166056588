var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentRole === 4 || _vm.currentRole === 5)?_c('div',{staticClass:"absent"},[_c('h2',[_vm._v("Side Activities List")]),_c('v-row',{class:'absent-header justify-space-between align-center'},[_c('v-col',[_c('div',{class:' d-flex justify-start'},[_c('v-select',{class:'select-input',attrs:{"item-text":"location","append-icon":"keyboard_arrow_down","item-value":"locationID","items":_vm.listLocation,"label":"Center","outlined":"","multiple":"","dense":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":function($event){return _vm.toggle()}}},[_c('v-list-item-action',[(_vm.listLocation.length == _vm.locationIDs.length)?_c('v-icon',[_vm._v(" mdi-close-box ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
var index = ref.index;
return [(index == 0)?_c('span',[_vm._v(" "+_vm._s(_vm.locationIDs.length)+" selected ")]):_vm._e()]}}],null,false,4089567569),model:{value:(_vm.locationIDs),callback:function ($$v) {_vm.locationIDs=$$v},expression:"locationIDs"}})],1)])],1),_c('div',{staticClass:"d-flex justify-space-between align-center mb-4"},[_c('h4',[_vm._v("Total : "+_vm._s(_vm.listActivities.length)+" Activities")]),(_vm.permission.cFull)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'create-activity' })}}},[_vm._v("Create new")]):_vm._e()],1),(_vm.loading)?[_c('div',{staticClass:"pa-6"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)]:_vm._e(),[(!_vm.loading)?_c('v-data-table',{staticClass:"elevation-0 absent-table",attrs:{"headers":_vm.headers,"items":_vm.filtered,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","id":'absent-table',"fixed-header":"","height":"69vh"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',{staticClass:"fixed-row-filter"},_vm._l((props.headers),function(header){return _c('th',{key:header.text},[(_vm.filters.hasOwnProperty(header.value))?_c('div',[(_vm.filters.hasOwnProperty(header.value))?_c('Autocomplete',{attrs:{"styleBorder":'border-bottom',"keyFilter":header.value,"selectedInit":_vm.filters[header.value],"listItem":_vm.groupColumnValueList(header.value)},on:{"setFilter":_vm.setFilter}}):_vm._e()],1):_vm._e()])}),0)]}},{key:"item.no",fn:function(ref){
var index = ref.index;
return [_c('p',{on:{"click":function($event){return _vm.view(_vm.item.userID)}}},[_vm._v(_vm._s(index + 1))])]}},{key:"item.createBy",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"link",on:{"click":function($event){return _vm.viewAccount(item.createUserID)}}},[_vm._v(_vm._s(item.createBy))])]}},{key:"item.locationIDs",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.locationIDs.map(function (e) { return e.location; }).join(' , ')))])]}},{key:"item.saveType",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"status",style:({
            color: '#ffff',
            background: item.saveType === 'PUBLIC' ? '#27AE60' : '#F6B91A',
          })},[_vm._v(" "+_vm._s(item.saveType)+" ")])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(_vm.permission.cFull || _vm.permission.cModify)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":require("../../assets/images/icon-edit-pen.svg"),"alt":"","srcset":""},on:{"click":function($event){return _vm.editActivity(item.activityID)}}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"ml-2 mr-2",attrs:{"src":require("../../assets/images/icon-eye.svg"),"alt":"","srcset":""},on:{"click":function($event){return _vm.viewDetailActivity(item.activityID)}}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("View activity")])]),(_vm.permission.cFull)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":require("../../assets/images/icon-delete.svg"),"alt":"","srcset":""},on:{"click":function($event){_vm.$refs.confirmDelete.open(), (_vm.deleteActivityID = item.activityID)}}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_vm._e()],1)]}}],null,true)}):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"pl-10 mt-3 pr-10",attrs:{"color":"#fff"}},[_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{staticStyle:{"height":"32px"},attrs:{"outlined":"","hide-details":"","dense":"","value":_vm.itemsPerPage,"items":[20, 50, 100, 200]},on:{"change":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"md":"4"}},[_c('h3')]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"md":"6"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"elevation":"0","total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1):_vm._e()],_c('popup-confirm',{ref:"confirmDelete",attrs:{"maxWidth":550,"confirmText":"Yes, delete it!","width":_vm.width},on:{"confirm":_vm.confirmDelete,"cancel":_vm.cancelDelete}},[_c('v-card-title',{class:'pt-0',attrs:{"slot":"icon"},slot:"icon"},[_c('div',{style:({ margin: '0 auto' })},[_c('img',{attrs:{"src":require("../../assets/images/icon-close-red.svg"),"alt":"","srcset":""}})])]),_c('v-card-title',{class:'pt-0 pb-0',attrs:{"slot":"title"},slot:"title"},[_c('h2',{staticClass:"text-center",style:({ width: '100%' })},[_vm._v("Confirm delete")])]),_c('v-card-title',{class:'pt-0',attrs:{"slot":"content"},slot:"content"},[_c('p',{staticClass:"text-center",style:({ width: '100%' })},[_vm._v("Are you sure to delete this activity?")])])],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }